export default {
    "Accessories": "Accessoires",
    "Account overview": "Account overzicht",
    "Add new address": "Nieuw adres toevoegen",
    "Add to cart": "In winkelwagen",
    "Add.": "Toev.",
    "Added to cart": "Toegevoegd",
    "Adding to cart": "Toevoegen",
    "Addition": "Toevoeging",
    "Address": "Adres",
    "Address not found": "Adres niet gevonden",
    "Addresses": "Adressen",
    "All features": "Alle Specificaties",
    "All rights reserved.": "Alle rechten voorbehouden.",
    "Apply": "Toepassen",
    "Are you an existing customer?": "Heb je al een account?",
    "Attributes": "Attributen",
    "Available from": "Leverbaar vanaf",
    "Bank": "Bank",
    "Best Use": "Beste gebruik",
    "Billing address": "Factuuradres",
    "Billing address is different from the address above": "Factuuradres is anders dan bovenstaand adres",
    "Business": "Zakelijk",
    "By clicking start payment you agree to our {url}": "Door op afrekenen te klikken, ga je akkoord met onze {url}",
    "Cancel": "Annuleren",
    "Care": "Zorg",
    "Cart": "Winkelwagen",
    "Categories": "Categorie\u00ebn",
    "Category": "Categorie",
    "Change password": "Verander je wachtwoord",
    "Checkout": "Afrekenen",
    "Choose a selection...": "Kies een selectie...",
    "Choose an option": "Kies een optie",
    "Choose size": "Kies maat",
    "Choose your {option}": "Kies je {option}",
    "Choose {option}": "Kies {option}",
    "City": "Plaats",
    "Clear all": "Alles wissen",
    "Click here to automatically retrieve your address": "Klik hier om je adres automatisch op te vragen",
    "Click here to enter the address manually.": "Klik hier om het adres handmatig in te voeren.",
    "Click {url} to continue shopping": "Klik {url} om verder te winkelen",
    "Close": "Sluiten",
    "CoC number": "KVK nummer",
    "Color": "Kleur",
    "Company": "Bedrijf",
    "Compare": "Vergelijk",
    "Compare product": "Vergelijk product",
    "Confirm new password": "Bevestig nieuw wachtwoord",
    "Confirm password": "Bevestig Wachtwoord",
    "Contact details": "Contactgegevens",
    "Contact us": "Neem contact met ons op",
    "Country": "Land",
    "Create account": "Account aanmaken",
    "Current password": "Huidige wachtwoord",
    "Date": "Datum",
    "Default billing address": "Standaard factuuradres",
    "Default shipping address": "Standaard verzendadres",
    "Description": "Omschrijving",
    "Dimensions": "Afmetingen",
    "Discount products": "Kassakoopjes",
    "Discover our {categoryTitle}": "Ontdek onze {categoryTitle}",
    "Do you have a promotional code?": "Heb je een kortingscode?",
    "Do you receive our newsletter?": "Ontvang jij onze nieuwsbrief?",
    "Edit": "Wijzig",
    "Edit address": "Adres wijzigen",
    "Edit address details": "Wijzig adresgegevens",
    "Edit personal settings": "Wijzig persoonlijke instellingen",
    "Email address": "E-mailadres",
    "Enter a correct value": "Voer een juiste waarde in",
    "Essential details": "Essenti\u00eble details",
    "Experience the benefits": "Ervaar de voordelen",
    "Extra Addresses": "Extra adres",
    "Failed to add to cart": "Kan niet toevoegen aan winkelwagen",
    "Featured": "Uitgelicht",
    "Features": "Functies",
    "Female": "Vrouw",
    "Filter": "Filter",
    "First name": "Voornaam",
    "Forgot password": "Wachtwoord vergeten",
    "Forgot password?": "Wachtwoord vergeten?",
    "Free": "Gratis",
    "Grand total": "Totaal",
    "Het wachtwoord heeft minstens %1 tekens nodig. Maak een nieuw wachtwoord en probeer het opnieuw.": "Het wachtwoord heeft minstens 8 tekens nodig. Maak een nieuw wachtwoord en probeer het opnieuw.",
    "Highlights": "Highlights",
    "House no.": "Huisnummer",
    "House number": "Huisnummer",
    "If an account is found with {email}, you will receive an email with a link to reset your password.": "Als er een account is gevonden met {email}, zul je een e-mail ontvangen met een link om je wachtwoord te resetten.",
    "If an account with the given email address exists you will receive a password reset email.": "Als er een account met het opgegeven e-mailadres bestaat, ontvang je een e-mail voor het opnieuw instellen van je wachtwoord.",
    "Included equipment": "Inbegrepen uitrusting",
    "Including items": "Inclusief items",
    "Individual": "Particulier",
    "Invalid address. Are you sure it is correct?": "Ongeldig adres. Weet je zeker dat het juist is ingevuld?",
    "Invalid captcha.": "Ongeldige captcha.",
    "Item": "Artikel",
    "Join now": "Word nu lid",
    "Last name": "Achternaam",
    "Loading shopping cart items...": "Winkelwagen aan het laden...",
    "Log in": "Inloggen",
    "Login": "Login",
    "Login here": "Hier inloggen",
    "Logout": "Uitloggen",
    "Male": "Man",
    "Material & maintenance": "Materiaal & onderhoud",
    "Materials, care & storage": "Materialen, verzorging & opslag",
    "Measurements & weight": "Afmetingen & gewicht",
    "Minimum van verschillende klassen van tekens in wachtwoord is %1. Soorten tekens: kleine letters, hoofdletters, cijfers en speciale tekens.": "Minimum van verschillende klassen van tekens in wachtwoord is 3. Soorten tekens: kleine letters, hoofdletters, cijfers en speciale tekens.",
    "More of this": "Meer zoals dit",
    "Mr.": "Dhr.",
    "Mrs.": "Mevr.",
    "My account": "Mijn account",
    "My favorites": "Mijn favorieten",
    "My orders": "Mijn bestellingen",
    "My reviews": "Mijn reviews",
    "Name": "Voornaam",
    "Name (A-Z)": "Naam (A-Z)",
    "Name (Z-A)": "Naam (Z-A)",
    "New customers": "Nieuwe klanten",
    "New password": "Nieuw wachtwoord",
    "Newsletter": "Nieuwsbrief",
    "Newsletters": "Nieuwsbrief",
    "No addresses available": "Geen adres beschikbaar",
    "No default billing address available": "Er is geen standaard factuuradres beschikbaar",
    "No default shipping address available": "Er is geen standaard verzendadres beschikbaar",
    "No posts found": "Geen berichten gevonden",
    "No products were found in this category.": "Er zijn geen producten gevonden in deze categorie.",
    "Not in stock": "Niet op voorraad",
    "One or more products are out of stock.": "Een of meer producten zijn niet op voorraad.",
    "Order #": "Bestelling #",
    "Order {increment}": "Bestelling {increment}",
    "Order id": "Ordernummer",
    "Order placed": "Bestelling afronden",
    "Order not found": "Bestelling niet gevonden",
    "Ordered items": "Bestelde artikelen",
    "Other series": "Andere series",
    "Packaging": "Verpakking",
    "Password": "Wachtwoord",
    "Payment method": "Betaalmethode",
    "Payment methods": "Betalen",
    "Payment": "Betalen",
    "Personal settings": "Persoonlijke instellingen",
    "Phone number": "Telefoonnummer",
    "Please enter a valid code": "Voer een geldige code in",
    "Postal code": "Postcode",
    "Postal code + house number": "Postcode + Huisnummer",
    "Prefix": "Aanhef",
    "Price": "Prijs",
    "Price (Ascending)": "Prijs (Laag-Hoog)",
    "Price (Descending)": "Prijs (Hoog-Laag)",
    "Product has been added to your shopping cart": "Product is toegevoegd aan je winkelwagen",
    "Product size": "Productgrootte",
    "Product video": "Product video",
    "Quantity": "Aantal",
    "Question or remark": "Vraag of opmerking",
    "Rate us": "Beoordeel ons",
    "Read less": "Lees minder",
    "Read more": "Lees meer",
    "Recent orders": "Recente bestellingen",
    "Recent posts": "Recente berichten",
    "Recently viewed": "Laatst bekeken",
    "People are also interested in": "Mensen zijn ook ge\u00efnteresseerd in",
    "Related categories": "Gerelateerde categorie\u00ebn",
    "Related products": "Gerelateerde producten",
    "Remove": "Verwijder",
    "Request password": "Wachtwoord aanvragen",
    "Review not found": "Beoordeling niet gevonden",
    "Reviews": "Beoordelingen",
    "Rider": "Rider",
    "SKU": "SKU",
    "Save": "Opslaan",
    "Save address": "Adres oplsaan",
    "Search": "Zoeken",
    "Search results": "Zoekresultaten",
    "Search results for &ldquo;{term}&rdquo;": "Zoekresultaten voor &ldquo;{term}&rdquo;",
    "Search results for \"{term}\"": "Zoekresultaten voor \"{term}\"",
    "Secure connection": "Veilige verbinding",
    "Select a country": "Selecteer een land",
    "Select payment": "Selecteer betaalmethode",
    "Select payment method": "Naar betalen",
    "Select shipping": "Selecteer bezorgmethode",
    "Select shipping method": "Naar bezorgen",
    "Select your address": "Selecteer je gewenste adres",
    "Send": "Versturen",
    "Ship to": "Verzend naar",
    "Shipping": "Verzending",
    "Shipping address": "Verzendadres",
    "Shipping method": "Verzendmethode",
    "Shipping methods are not available right now": "Bezorgmethoden zijn momenteel niet beschikbaar",
    "Shipping options will be updated during checkout": "De verzendkosten worden bij het afrekenen bijgewerkt",
    "Show everything in": "Toon alles in",
    "Show less": "Toon minder",
    "Show more": "Toon meer",
    "Show more photo\"s": "Toon meer foto\"s",
    "Showing {productsPerPage}/{totalProducts} products": "Geen producten | 1 van 1 | {productsPerPage} van {totalProducts}",
    "Showing {totalProductCount} products": "{totalProductCount} producten",
    "Size Guide": "Maattabel",
    "Sold out": "Uitverkocht",
    "Something went wrong while recovering your password": "Er ging iets mis tijdens het herstellen van je wachtwoord",
    "Something went wrong, please try again": "Er is iets mis gegaan, probeer het nog een keer",
    "Sort by": "Sorteren",
    "Specifications": "Specificaties",
    "Start payment": "Afrekenen",
    "Store locator": "Storelocator",
    "Street": "Straat",
    "Subscribe to our newsletter": "Schrijf je in voor onze nieuwsbrief",
    "Subscribe to the newsletter and never miss a promotion!": "Schrijf je in voor de nieuwsbrief en mis geen enkele actie!",
    "Subtotal": "Subtotaal",
    "Successfully created an account, you can log in with your new credentials": "Je account is succesvol aangemaakt, je kunt vanaf nu inloggen met je gegevens",
    "Successfully removed address": "Adres is verwijderd",
    "Successfully saved address": "Je adres is opgeslagen",
    "Successfully updated customer": "Je wijzigingen zijn opgeslagen",
    "Successfully updated newsletter subscription": "Nieuwsbriefabonnement succesvol bijgewerkt",
    "Suitable for": "Geschikt voor",
    "Suited for": "Geschikt voor",
    "Summary": "Overzicht",
    "Sustainability": "Duurzaamheid",
    "Tags": "Tags",
    "Telephone": "Telefoonnummer",
    "Terms & conditions": "Algemene voorwaarden",
    "Thank you for your purchase!": "Bedankt voor je bestelling!",
    "The account login is incorrect or your account has been temporarily disabled. Wait and try again later.": "De account aanmelding is onjuist of uw account is tijdelijk uitgeschakeld. Wacht en probeer het later opnieuw.",
    "The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.": "De accountaanmelding was onjuist of je account is tijdelijk uitgeschakeld. Probeer het later opnieuw.",
    "There are no available options for this product.": "Er zijn geen beschikbare opties voor dit product.",
    "This personal page offers you an overview of the most recent activities. You can, among other things, view orders and update account information. Click on a link below to get started!": "Deze persoonlijke pagina biedt je een overzicht van de meest recente activiteiten. Je kunt onder andere bestellingen bekijken en account informatie bijwerken!",
    "This product is currently sold out": "Dit product is momenteel uitverkocht",
    "To checkout": "Naar afrekenen",
    "To shopping cart": "Naar winkelwagen",
    "Total": "Totaal",
    "Unable to remove address": "Kan adres niet verwijderen",
    "Unable to save customer": "Het opslaan is niet gelukt",
    "Unable to update newsletter subscription": "Kan nieuwsbriefabonnement niet bijwerken",
    "Usage": "Gebruik",
    "VAT number": "BTW nummer",
    "View details": "Bekijk details",
    "View less": "Bekijk minder",
    "View more": "Bekijk meer",
    "View more {categoryTitle}": "Bekijk meer {categoryTitle}",
    "View order": "Bekijk bestelling",
    "View product": "Bekijk product",
    "Volume": "Volume",
    "We have found the following address": "We hebben het volgende adres gevonden",
    "Welcome {name}": "Welkom {name}",
    "What are you looking for?": "Waar ben je naar op zoek?",
    "Wishlist": "Verlanglijstje",
    "Within 15 minutes you will receive an email confirming your order with all the details. If you have questions about the status of your order, please contact customer support.": "Binnen 15 minuten ontvang je een e-mail ter bevestiging van je bestelling met alle details. Als je vragen hebt over de status van je bestelling, neem dan contact op met de klantenservice.",
    "Write a review": "Schrijf een review",
    "You are now logged out and will return to our home page within 5 seconds.": "U bent nu uitgelogd en zult binnen 5 seconden terugkeren naar de homepagina.",
    "You are now signed out": "Je bent nu ingelogd",
    "You are subscribed to our newsletter": "Je bent ingeschreven voor onze nieuwsbrief",
    "You have no product(s) in your shopping cart.": "Je hebt geen product(en) in je winkelwagen.",
    "You have not added any favorite products yet. Click on the heart next to a product while shopping to save it as your favourite.": "Je hebt nog geen favorieten toegevoegd. Klik tijdens het winkelen op het hartje om een product toe te voegen aan je favorieten.",
    "You have not placed any orders yet": "Je hebt nog geen bestelling bij ons geplaatst",
    "You have not reviewed any products yet": "Je hebt nog geen product reviews achter gelaten",
    "Your address details": "Je adresgegevens",
    "Your applied coupon code is {couponCodeInput}.": "Je toegepaste kortingscode is {couponCodeInput}.",
    "Your details": "Je gegevens",
    "Your email": "Je e-mailadres",
    "Your message": "Je bericht",
    "Your message has been sent.": "Je bericht is verzonden.",
    "Your order has been successfully placed.": "Je bestelling is succesvol geplaatst.",
    "Your order id is:": "Jouw order id is:",
    "Your password has been updated": "Je wachtwoord is bijgewerkt",
    "back": "Terug",
    "here": "hier",
    "incl. VAT": "incl. BTW",
    "name_s": "naam",
    "out of stock": "uitverkocht",
    "price": "prijs",
    "Alternatives of this product": "Alternatieven van dit product",
    "ratings": "beoordelingen",
    "subscribe": "Schijf je in",
    "successfully logged out": "succesvol uitgelogd",
    "validation.email": "Vul een geldig e-mailadres in",
    "validation.required": "Dit is een verplicht veld",
    "More detailed product images": "Meer gedetailleerde productafbeeldingen",
    "Subscribe to our personalized newsletter for exclusive NOMAD news, offers, and make sure you don't miss a single promotion!": "Abonneer je op onze gepersonaliseerde nieuwsbrief voor exclusieve NOMAD nieuws, aanbiedingen, en zorg dat je geen enkele actie mist!",
    "sold out": "uitverkocht",
    "Show alternatives": "Bekijk alternatieven",
    "All images": "Alle afbeeldingen",
    "Learn more about the": "Meer informatie over de",
    "Customers also bought": "Klanten kochten ook",
    "Suggestions for the ultimate Mistral experience": "Suggesties voor de ultieme Mistral ervaring",
    "Often bought together with": "Vaak samengekocht met",
    "Others also viewed": "Anderen bekeken ook",
    "Temperature": "Temperatuur",
    "Essential items": "Vaak samen gekocht met",
    "Enter Billing Address": "Vul factuuradres in",
    "Enter Shipping Address": "Vul verzendadres in",
    "Order Summary": "Besteloverzicht",
    "About this product": "Alles over dit product",
    "Compare {count} products": "Vergelijk {count} producten",
    "{productsPerPage}/{totalProducts} Products": "{productsPerPage}/{totalProducts} Producten",
    "Select {label}": "Kies {label}",
    "Select your {label}": "Kies je {label}",
    "Chosen {label}": "Gekozen {label}",
}
